<template>
  <div class="news">
    <head-nav></head-nav>
    <about-img></about-img>

    <div class="news-box">
      <h3 class="news-box-title">
        {{post.title}}
      </h3>

      <div class="news-details-box">
        <div class="news-details-box-infos">
          <span class="news-details-box-infos-titme">时间
            <span>{{post.createdAt  | formatDate}}</span>
          </span>
          <span class="news-details-box-infos-views">点击量
            <span>{{hitCount}}</span>
          </span>
        </div>

        <div
          class="news-details"
          v-html="post.content"
        ></div>
        <div class="news-details-page">
          <div class="news-details-page-list">
            上一篇
            <span
              @click="gotoPost(beforePost.id)"
              v-if="beforePost"
            >{{beforePost.title}}</span>
            <span v-else>没有更多</span>
          </div>
          <div class="news-details-page-list">
            下一篇
            <span
              @click="gotoPost(afterPost.id)"
              v-if="afterPost"
            >{{afterPost.title}}</span>
            <span v-else>没有更多</span>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>

  </div>
</template>

<script>
import gql from 'graphql-tag';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    HeadNav,
    AboutImg,
    Footers,
  },
  apollo: {
    post: {
      query: gql`
        query($id: ID!) {
          post: Post(where: { id: $id }) {
            title
            content
            hitCount
            createdAt
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
    },
    beforeAfterPost: {
      query: gql`
        query($currentTime: DateTime) {
          afterPost: allPosts(
            where: { createdAt_gt: $currentTime }
            sortBy: createdAt_ASC
            first: 1
          ) {
            id
            title
          }
          beforePost: allPosts(
            where: { createdAt_lt: $currentTime }
            sortBy: createdAt_DESC
            first: 1
          ) {
            id
            title
          }
        }
      `,
      variables() {
        return {
          currentTime: this.post.createdAt,
        };
      },
      update(value) {
        return value;
      },
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
    },
  },
  async created() {
    const result = await this.$apollo.mutate({
      // 查询语句
      mutation: gql`
        mutation($id: ID!) {
          IncrementPostHitCount(
           id: $id
          ) {
            hitCount
          }
        }
      `,
      // 参数
      variables: {
        id: this.id,
      },
    });
    this.hitCount = result.data.IncrementPostHitCount.hitCount;
  },
  data() {
    return {
      hitCount: 0,
      post: {
        title: '',
        content: '',
        hitCount: '',
        createdAt: '',
      },
      beforeAfterPost: {
        beforePost: [],
        afterPost: [],
      },
    };
  },
  computed: {
    beforePost() {
      const { beforePost } = this.beforeAfterPost;
      if (beforePost.length > 0) {
        return beforePost[0];
      }
      return null;
    },
    afterPost() {
      const { afterPost } = this.beforeAfterPost;
      if (afterPost.length > 0) {
        return afterPost[0];
      }
      return null;
    },
  },
  methods: {
    gotoPost(id) {
      this.$router.push({
        name: 'about-news-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  background: #f8f8f8;
  .news-box {
    margin: 0 30px;

    &-title {
      padding-top: 50px;
      text-align: center;
      font-size: 36px;
      line-height: 40px;
      font-weight: 100;
    }

    .news-details-box {
      margin-top: 40px;

      &-infos {
        text-align: center;

        span {
          margin: 0 30px;
          font-size: 24px;
        }
      }

      .news-details {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        padding: 40px 0 60px 0;
        margin: 40px 0;
        font-size: 28px;
        line-height: 34px;
        width: 100%;

        ::v-deep p {
          width: 100%;

          img {
            width: 100% !important;
            height: 280px;
            margin: 30px auto;
          }
        }
      }

      .news-details-page {
        margin-bottom: 100px;

        &-list {
          margin-bottom: 30px;
          font-size: 24px;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
